/* VideoModal styles */

.container {
  @apply h-full w-full;
}

.iframe-container {
  /* skeleton */
  @apply flex h-full w-full content-center items-center justify-center rounded-2xl;

  iframe {
    @apply rounded-2xl;
  }
}
